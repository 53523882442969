import * as Yup from 'yup'

Yup.addMethod(Yup.number, 'number', function (message) {
  return this.test('number', message || 'Value must be number', (value) =>
    value ? /^[0-9]*$/.test(value) : true
  )
})

Yup.addMethod(Yup.number, 'decimal', function (message) {
  return this.test('decimal', message || 'Value must be a valid decimal', (value) =>
    value ? /^\d{1,13}(\.\d{1,6})?$/.test(value) : true
  )
})

Yup.addMethod(Yup.number, 'exchangeRate', function (message) {
  return this.test('exchangeRate', message || 'Value must be maximum 6 decimal', (value) =>
    value ? /^\d{1,13}(\.\d{1,6})?$/.test(value) : true
  )
})

Yup.addMethod(Yup.string, 'url', function (message) {
  return this.test('url', message || 'Enter a valid website', (value) =>
    value
      ? /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g.test(
          value
        )
      : true
  )
})

Yup.addMethod(
  Yup.array,
  'atleastOneOfFieldInObject',
  function (message = 'Atleast one of field is required', field) {
    return this.test('atleastOneOfFieldInObject', '', (value, ref) =>
      !value.some((obj) => obj[field])
        ? ref.createError({
            message,
            path: `${ref.path}[0].${field}`
          })
        : true
    )
  }
)

Yup.addMethod(Yup.string, 'dateFormat', function (message) {
  return this.test('dateFormat', message || 'Enter a valid date format', (value) =>
    value ? /^.*\$\{.*((Y)|(M)|(D)).*\}.*/g.test(value) : true
  )
})
